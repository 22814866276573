import React, { useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import downloadIcon from '../images/download-icon.svg';

const StyledEquipmentTabs = styled.section`
  ${sectionMargins('60px')};
  background-color: ${brandColours.quaternary};
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;

  ${minBreakpointQuery.small`
    flex-direction: row;
  `}
`;

const StyledItem = styled.li`
  flex-grow: 1;
`;

const StyledTab = styled.button`
  padding: 20px 10px;
  height: 100%;
  width: 100%;
  color: ${brandColours.tertiary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.bold};
  ${fontSize(15)};
  line-height: 1.5;
  background-color: ${brandColours.quaternary};
  border: none;
  cursor: pointer;
  transition: ${standardTransition('color')},
    ${standardTransition('background-color')};

  ${minBreakpointQuery.small`
    padding-top: 25px;
    padding-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-bottom: 30px;
    ${fontSize(16)};
  `}

  ${({ active }) => {
    if (active) {
      return `
        color: ${standardColours.white};
        background-color: ${brandColours.primary};
      `;
    }
  }}
`;

const StyledContentWrapper = styled.div`
  ${sectionPaddings(undefined, '80px')};
  background-color: ${brandColours.primary};
`;

const StyledContent = styled.article`
  background-color: ${brandColours.quaternary};

  ${({ active }) => {
    if (!active) {
      return `
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledContentHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledTable = styled.table`
  width: 100%;
  background-color: ${brandColours.primary};
`;

const StyledTableBody = styled.tbody``;

const StyledTableRow = styled.tr`
  border: 2px solid ${brandColours.primary};
`;

const StyledTableHeader = styled.th`
  padding: 16px 20px;
  line-height: 1.7;
  text-align: left;
  background-color: ${brandColours.quaternary};
`;

const StyledTableData = styled.td`
  padding: 16px 20px;
  ${fontSize(15)};
  line-height: 1.7;
  white-space: break-spaces;
  background-color: ${brandColours.quaternary};

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}
`;

const StyledImages = styled.div`
  padding: 10px 30px;
`;

const StyledImageWrapper = styled.div`
  margin: 20px 0;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledImageHeading = styled.h3`
  margin-bottom: 10px;
  ${fontSize(20)};

  ${minBreakpointQuery.small`
    margin-bottom: 15px;
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
    ${fontSize(26)};
  `}
`;

const StyledImage = styled.div`
  margin: auto;
  max-width: ${({ maxWidth }) => (maxWidth > 1080 ? '1080' : maxWidth)}px;
`;

const StyledDownloads = styled.div`
  padding: 30px;

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

const StyledDownload = styled.a`
  position: relative;
  display: block;
  margin-top: 25px;
  padding-left: 40px;
  font-weight: ${fontWeights.medium};
  line-height: 1.7;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledDownloadIcon = styled(Svg)`
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 25px;
  fill: ${brandColours.primary};
`;

export const EquipmentTabs = ({
  specifications,
  workingRange,
  dutyChart,
  dimensions,
  downloads,
}) => {
  const [activeTabId, setActiveTabId] = useState(1);

  const items = [
    {
      id: 1,
      heading: 'Specifications',
    },
  ];

  if (workingRange.length > 0) {
    items.push({
      id: 2,
      heading: 'Working Range',
    });
  }

  if (dutyChart.length > 0) {
    items.push({
      id: 3,
      heading: 'Duty Chart',
    });
  }

  if (dimensions.length > 0) {
    items.push({
      id: 4,
      heading: 'Dimensions',
    });
  }

  if (downloads.length > 0) {
    items.push({
      id: 5,
      heading: 'Downloads',
    });
  }

  return (
    <StyledEquipmentTabs id="equipment-info">
      <Container>
        <StyledList>
          {items.map(({ id, heading }) => (
            <StyledItem>
              <StyledTab
                active={id === activeTabId}
                onClick={() => {
                  setActiveTabId(id);
                }}
              >
                {heading}
              </StyledTab>
            </StyledItem>
          ))}
        </StyledList>
      </Container>
      <StyledContentWrapper>
        <Container>
          <StyledContent active={activeTabId === 1}>
            <StyledContentHeading>Specifications</StyledContentHeading>
            <StyledTable>
              <StyledTableBody>
                {specifications.map(({ name, data }) => (
                  <StyledTableRow>
                    <StyledTableHeader>{name}</StyledTableHeader>
                    <StyledTableData>{data}</StyledTableData>
                  </StyledTableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledContent>
          {workingRange.length > 0 && (
            <StyledContent active={activeTabId === 2}>
              <StyledContentHeading>Working Range</StyledContentHeading>
              <StyledImages>
                {workingRange.map(
                  ({ heading, image: { url, fluid, alt, width } }) => (
                    <StyledImageWrapper>
                      {heading && (
                        <StyledImageHeading>{heading}</StyledImageHeading>
                      )}
                      <StyledImage maxWidth={width}>
                        <a href={url} target="_blank" rel="noreferrer">
                          <Img fluid={fluid} alt={alt} />
                        </a>
                      </StyledImage>
                    </StyledImageWrapper>
                  )
                )}
              </StyledImages>
            </StyledContent>
          )}
          {dutyChart.length > 0 && (
            <StyledContent active={activeTabId === 3}>
              <StyledContentHeading>Duty Chart</StyledContentHeading>
              <StyledImages>
                {dutyChart.map(
                  ({ heading, image: { url, fluid, alt, width } }) => (
                    <StyledImageWrapper>
                      {heading && (
                        <StyledImageHeading>{heading}</StyledImageHeading>
                      )}
                      <StyledImage maxWidth={width}>
                        <a href={url} target="_blank" rel="noreferrer">
                          <Img fluid={fluid} alt={alt} />
                        </a>
                      </StyledImage>
                    </StyledImageWrapper>
                  )
                )}
              </StyledImages>
            </StyledContent>
          )}
          {dimensions.length > 0 && (
            <StyledContent active={activeTabId === 4}>
              <StyledContentHeading>Dimensions</StyledContentHeading>
              <StyledImages>
                {dimensions.map(
                  ({ heading, image: { url, fluid, alt, width } }) => (
                    <StyledImageWrapper>
                      {heading && (
                        <StyledImageHeading>{heading}</StyledImageHeading>
                      )}
                      <StyledImage maxWidth={width}>
                        <a href={url} target="_blank" rel="noreferrer">
                          <Img fluid={fluid} alt={alt} />
                        </a>
                      </StyledImage>
                    </StyledImageWrapper>
                  )
                )}
              </StyledImages>
            </StyledContent>
          )}
          {downloads.length > 0 && (
            <StyledContent active={activeTabId === 5}>
              <StyledContentHeading>Downloads</StyledContentHeading>
              <StyledDownloads>
                {downloads.map(({ name, file: { url, format, size } }) => {
                  const fileSizeKB = (size / Math.pow(1024, 1)).toFixed(1);
                  const fileSizeMB = (size / Math.pow(1024, 2)).toFixed(1);
                  const fileSize =
                    fileSizeMB > 0 ? `${fileSizeMB}MB` : `${fileSizeKB}KB`;

                  return (
                    <StyledDownload href={url} target="_blank" download>
                      <StyledDownloadIcon image={downloadIcon} />
                      {name} ({format.toUpperCase()} - {fileSize})
                    </StyledDownload>
                  );
                })}
              </StyledDownloads>
            </StyledContent>
          )}
        </Container>
      </StyledContentWrapper>
    </StyledEquipmentTabs>
  );
};

export default EquipmentTabs;
