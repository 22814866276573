import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import CallbackButton from './CallbackButton';
import CallCta from './CallCta';

const StyledEquipmentDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.h1`
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '30px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledSubHeading = styled.h2`
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};

  ${minBreakpointQuery.tiny`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledPrice = styled.p`
  margin-top: 14px;
  color: ${brandColours.primary};

  ${minBreakpointQuery.tiny`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledDescription = styled.p`
  margin-top: 16px;
  line-height: 1.7;
`;

const StyledLink = styled.a`
  display: block;
  margin-top: 16px;
  color: ${brandColours.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const StyledTopFeatures = styled.div`
  margin: 25px 0;
  padding: 20px 0;
  border-top: 1px solid ${brandColours.quaternary};
  border-bottom: 1px solid ${brandColours.quaternary};

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
  `}
`;

const StyledTopFeaturesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px 20px;
  margin-top: 20px;
`;

const StyledTopFeaturesItem = styled.li`
  line-height: 1.5;
`;

const StyledCta = styled.div`
  margin-top: auto;

  ${maxBreakpointQuery.tsmall`
    text-align: center;
  `}

  ${minBreakpointQuery.tsmall`
    display: flex;
    align-items: center;
  `}
`;

const StyledButton = styled(CallbackButton)`

  ${maxBreakpointQuery.tsmall`
    margin-bottom: 20px;
    width: 100%;
  `}

  ${minBreakpointQuery.tsmall`
    margin-right: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-right: 30px;
    width: 100%;
  `}
`;

const EquipmentDetails = ({ title, price, description, topFeatures }) => (
  <StyledEquipmentDetails>
    <StyledHeading>{title}</StyledHeading>
    {price && <StyledPrice>{price}</StyledPrice>}
    {description && <StyledDescription>{description}</StyledDescription>}
    <StyledLink
      role="button"
      onClick={() =>
        document
          .getElementById('equipment-info')
          .scrollIntoView({ behavior: 'smooth' })
      }
    >
      View technical specifications
    </StyledLink>
    {topFeatures.length > 0 && (
      <StyledTopFeatures>
        <StyledSubHeading>Top Features</StyledSubHeading>
        <StyledTopFeaturesList>
          {topFeatures.map(({ text }) => (
            <StyledTopFeaturesItem>{text}</StyledTopFeaturesItem>
          ))}
        </StyledTopFeaturesList>
      </StyledTopFeatures>
    )}
    <StyledCta>
      <StyledButton />
      <CallCta />
    </StyledCta>
  </StyledEquipmentDetails>
);

export default EquipmentDetails;
