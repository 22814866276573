import React, { useRef, useState } from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { brandColours } from '../styles';

const StyledEquipmentGallery = styled.section``;

const StyledNavImages = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 20px;
`;

const StyledNavImage = styled.div`
  margin-left: -2px;
  border: 2px solid ${brandColours.quaternary};
  cursor: pointer;

  ${({ current }) => {
    if (current) {
      return css`
        z-index: 1;
        border-color: ${brandColours.primary};
      `;
    }
  }};

  &:first-child {
    margin-left: 0;
  }
`;

const EquipmentGallery = ({ images: { mainImages, navImages } }) => {
  const slider = useRef();
  const [currentNavItem, setCurrentNavItem] = useState(0);

  const sliderOptions = {
    arrows: false,
  };

  return (
    <StyledEquipmentGallery>
      {mainImages && (
        <>
          <SlickSlider ref={slider} {...sliderOptions}>
            {mainImages.map(({ id, fluid, alt }) => (
              <Img key={id} fluid={fluid} alt={alt} />
            ))}
          </SlickSlider>
          {navImages.length > 1 && (
            <StyledNavImages>
              {navImages.map(({ id, fluid, alt }, i) => (
                <StyledNavImage
                  current={currentNavItem === i}
                  onClick={() => {
                    slider.current.slickGoTo(i);
                    setCurrentNavItem(i);
                  }}
                >
                  <Img key={id} fluid={fluid} alt={alt} />
                </StyledNavImage>
              ))}
            </StyledNavImages>
          )}
        </>
      )}
    </StyledEquipmentGallery>
  );
};

export default EquipmentGallery;
