import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Container, Grid } from '../components/ui';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import EquipmentGallery from '../components/EquipmentGallery';
import EquipmentDetails from '../components/EquipmentDetails';
import EquipmentTabs from '../components/EquipmentTabs';
import AccordionBlock from '../components/AccordionBlock';

const EquipmentSingleTemplate = ({
  data: {
    datoCmsEquipment: {
      seoMetaTags,
      title,
      price,
      description,
      topFeatures,
      mainGalleryImages,
      navGalleryImages,
      specifications,
      workingRange,
      dutyChart,
      dimensions,
      downloads,
    },
    datoCmsEquipmentSitewide: { faqs },
  },
}) => (
  <Layout isEquipmentSingle={true}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Breadcrumbs
        breadcrumb={{ slug: 'equipment', text: 'Equipment' }}
        currentPageTitle={title}
      />
      <Container>
        <Grid>
          <EquipmentGallery
            images={{
              mainImages: mainGalleryImages,
              navImages: navGalleryImages,
            }}
          />
          <EquipmentDetails
            title={title}
            price={price}
            description={description}
            topFeatures={topFeatures}
          />
        </Grid>
      </Container>
      <EquipmentTabs
        specifications={specifications}
        workingRange={workingRange}
        dutyChart={dutyChart}
        dimensions={dimensions}
        downloads={downloads}
      />
      {faqs && (
        <AccordionBlock heading={faqs.heading} items={faqs.accordionItems} />
      )}
    </main>
  </Layout>
);

export const EquipmentSingleTemplateQuery = graphql`
  query EquipmentSingleTemplateQuery($id: String!) {
    datoCmsEquipment(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      price
      description
      topFeatures {
        text
      }
      mainGalleryImages: images {
        fluid(
          maxHeight: 480
          maxWidth: 700
          imgixParams: { auto: "compress", fit: "crop", h: "480", w: "700" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      navGalleryImages: images {
        fluid(
          maxHeight: 100
          maxWidth: 140
          imgixParams: { auto: "compress", fit: "crop", h: "100", w: "140" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      specifications {
        name
        data
      }
      workingRange {
        heading
        image {
          url
          fluid(maxWidth: 1080) {
            ...GatsbyDatoCmsFluid
          }
          alt
          width
        }
      }
      dutyChart {
        heading
        image {
          url
          fluid(maxWidth: 1080) {
            ...GatsbyDatoCmsFluid
          }
          alt
          width
        }
      }
      dimensions {
        heading
        image {
          url
          fluid(maxWidth: 1080) {
            ...GatsbyDatoCmsFluid
          }
          alt
          width
        }
      }
      downloads {
        name
        file {
          url
          format
          size
        }
      }
    }
    datoCmsEquipmentSitewide {
      faqs {
        ...AccordionBlockFragment
      }
    }
  }
`;

export default EquipmentSingleTemplate;
